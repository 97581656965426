import appReducer from './appReducer';
import authReducer from './authReducer';
import timeReducer from './timeReducer';
import { combineReducers } from 'redux';

export default combineReducers({
  time: timeReducer,
  app: appReducer,
  auth: authReducer,
});

import { useStage4SubmissionDocData, useStatDocData } from '../../../api/game';
import { stages } from '../../../lib/Problems';
import { calcRankMessage } from '../../../lib/stageResults';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import StageTitle from '../../../static/png/Stage_title.png';
import { SingleResult } from './Game';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CTResultScore,
  CommonBG,
  Mission,
  Overlay,
  OverlayContent,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface ResultFinalProps {
  onBack?: () => void;
}
const ResultFinal: React.FC<ResultFinalProps> = props => {
  const maxScore = stages.length * 10;

  const [showOverlay, setShowOverlay] = React.useState(-1);

  const { eventId } = useParams<'eventId'>();
  const uid = useCurrentUser()?.uid;
  const [submission] = useStage4SubmissionDocData(eventId, uid);
  const [stat] = useStatDocData(eventId);
  const rank = React.useMemo(
    () => calcRankMessage(submission?.totalScore ?? 0, 3),
    [submission?.totalScore, submission]
  );
  const average = React.useMemo(() => {
    return stat?.averageScore ?? 0;
  }, [stat]);

  return (
    <CommonBG
      withHeader
      withFooter
      headerOptions={{ buttonType: 'logout', logoType: 'CT' }}
    >
      <Overlay
        isVisible={showOverlay >= 0}
        onClick={() => {
          setShowOverlay(-1);
        }}
      >
        {showOverlay >= 0 && (
          <OverlayContent>
            <img src={stages[showOverlay].correctImage} />
            <p>{stages[showOverlay].answer}</p>
          </OverlayContent>
        )}
      </Overlay>
      <Rule>
        <Title src={StageTitle} />
        <Mission>
          隠されたルールを推測しながら、
          <br />
          全ての点を線でつなぎなさい。
        </Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 8分
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <CTResultScore
          score={submission?.totalScore ?? 0}
          maxScore={maxScore}
          rank={rank}
          average={average}
        />
        <Description>
          <h2>あなたの解答</h2>

          <ListWrapper>
            <ListHeader>
              <p>問題の解答例</p>
              <p>解答タイム</p>
              <p>ヒントの使用</p>
              <p>突破率</p>
            </ListHeader>
            {submission?.answers != null && (
              <>
                {submission?.answers.map((single: SingleResult, i: number) => {
                  return (
                    <SingleList key={`res-${i}`}>
                      <Example>
                        <StageNum>{stages[i].stage}</StageNum>
                        <div
                          onClick={() => {
                            setShowOverlay(i);
                          }}
                        >
                          <img src={stages[i].correctImage} />
                        </div>
                        <div>
                          <p>{stages[i].answer}</p>
                        </div>
                      </Example>

                      <TimeSec>
                        {single.success ? (
                          <>
                            <Check />
                            <p
                              style={{ marginLeft: '10px', fontSize: '3.0rem' }}
                            >
                              {single.timeSec}秒
                            </p>
                          </>
                        ) : (
                          <p style={{ opacity: 0.3 }}>(未解答)</p>
                        )}
                      </TimeSec>

                      <Hint>
                        {single.success && (
                          <>
                            {single.hintMethodOpenCount +
                              single.hintRuleOpenCount ==
                            0 ? (
                              <p style={{ opacity: 0.3 }}>未使用</p>
                            ) : (
                              <p style={{ color: '#FF0000' }}>
                                {single.hintMethodOpenCount +
                                  single.hintRuleOpenCount}
                                回使用
                              </p>
                            )}
                          </>
                        )}
                      </Hint>
                      <Rate>{single.rate}%</Rate>
                    </SingleList>
                  );
                })}
              </>
            )}
          </ListWrapper>
        </Description>
        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => {
              const historyUrl =
                'https://specc-dev.riddler.co.jp/history/' + eventId;
              location.href = historyUrl;
            }}
          >
            戻る
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const Rate = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-right: 20px;
`;

const Check = () => {
  return (
    <svg
      id='Component_-_Complete'
      data-name='Component - Complete'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='パス_206'
        data-name='パス 206'
        d='M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z'
        fill='#05aa70'
      />
      <path
        id='Checkbox'
        d='M4.6,8,0,3.4l.933-.933L4.6,6.067,10.667,0,11.6.933Z'
        transform='translate(4.001 6)'
        fill='#fff'
      />
    </svg>
  );
};

const ListWrapper = styled.div`
  border: 1px solid #ced4da;
`;

const ListHeader = styled.div`
  display: flex;
  background: #f3f4f6;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 12px;

  >p: nth-child(1) {
    flex: 1;
  }
  >p: nth-child(2) {
    flex: 0.3;
  }
  >p: nth-child(3) {
    flex: 0.3;
  }
  >p: nth-child(4) {
    flex: 0.15;
  }
`;

const StageNum = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  background: #343a40;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Example = styled.div`
  flex: 1;
  display: flex;

  img {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
  }

  > div:last-child {
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    width: 260px;

    p {
      margin: 10px;
    }
  }
`;

const TimeSec = styled.div`
  flex: 0.3;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: bold;
`;

const Hint = styled.div`
  flex: 0.3;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: bold;
  font-size: 1.8rem;
`;

const SingleList = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  &:nth-child(2n) {
    background: #ffffff;
  }

  &:nth-child(2n-1) {
    background: #f3f4f6;
  }
`;

const ResultHeader = styled.div`
  border-bottom: 4px solid #189bf2;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    margin: 2.4rem 0;
    width: 212px;
    height: 32px;
  }
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  width: 240px;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  width: 800px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  font-size: 1.6rem;
  color: #343a40;
  width: 100%;
  position: relative;
  margin: 0 auto;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

export default ResultFinal;

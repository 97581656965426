import { timeActions } from '../actions/timeActions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const STAGE_TIME_LIMIT = 120;

export interface TimeState {
  timerStartTime: number;
  timerRemainingTime: number | null;
  timerDuration: number;
  timerOffset: number;
  serverTimeOffset: number;
  noGaugeTransition: boolean;
}

const initState: TimeState = {
  timerStartTime: 0,
  timerRemainingTime: null,
  timerDuration: STAGE_TIME_LIMIT,
  timerOffset: 0,
  serverTimeOffset: -Date.now() / 1000,
  noGaugeTransition: true,
};

const timeReducer = reducerWithInitialState<TimeState>(initState)
  .case(timeActions.updateRemainingTime, (state): TimeState => {
    if (state.timerRemainingTime == null) return state;
    const now = Date.now() / 1000 + state.serverTimeOffset;
    const elapsedTime = now - state.timerStartTime + state.timerOffset;
    const timerRemainingTime = Math.floor(
      elapsedTime < state.timerDuration ? state.timerDuration - elapsedTime : 0
    );
    const timeDelta = state.timerRemainingTime - timerRemainingTime;
    if (timeDelta < 0) {
      return {
        ...state,
        timerOffset: -timeDelta,
        noGaugeTransition: true,
      };
    }
    const noGaugeTransition = timeDelta <= 0 || 2 <= timeDelta;
    return {
      ...state,
      timerRemainingTime,
      noGaugeTransition,
    };
  })
  .case(timeActions.clearTimer, (state): TimeState => {
    return { ...state, timerRemainingTime: null };
  })
  .case(timeActions.setTimer, (state, payload): TimeState => {
    const { startTime, duration } = payload;
    const timerStartTime = startTime
      ? startTime?.getTime() / 1000 + state.serverTimeOffset
      : payload.startTimeOnServer ?? Date.now() / 1000 + state.serverTimeOffset;
    return {
      ...state,
      timerStartTime,
      timerRemainingTime: duration,
      timerDuration: duration,
      timerOffset: 0,
    };
  })
  .case(timeActions.setTimerOffset, (state, payload): TimeState => {
    return {
      ...state,
      timerOffset: payload,
    };
  })
  .case(timeActions.setServerTimeOffset, (state, payload): TimeState => {
    return {
      ...state,
      serverTimeOffset: payload ?? 0,
    };
  });

export default timeReducer;

import { useGetElementProperty } from '../../hoc/useElementProperty';
import PointGoal from '../../static/svg/point_goal.svg';
import PointStart from '../../static/svg/point_start.svg';
import { initializeApp } from 'firebase/app';
import { setUncaughtExceptionCaptureCallback } from 'process';
import {
  useImperativeHandle,
  useEffect,
  createRef,
  useState,
  forwardRef,
  useRef,
} from 'react';
import styled from 'styled-components';

const gcd = (x: number, y: number): number => {
  return x % y ? gcd(y, x % y) : y;
};

interface FieldProps {
  question: number;
  widthCount: number;
  heightCount: number;
  startPosition: Point;
  goalPosition: Point;
  finished: boolean;
  points: boolean[][] | undefined;
  onCheck: (lines: Point[][]) => boolean[];
  onGoal: (passedPoints: number[], complete: boolean) => void;
}

export interface FieldHandler {
  onUndo(): void;
  onReset(): void;
  onResetAll(): void;
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

const Field: React.ForwardRefRenderFunction<FieldHandler, FieldProps> = (
  props: FieldProps,
  ref
) => {
  const [fieldSizeAtFinished, setFieldSizeAtFinished] = useState<Point>(
    new Point(0, 0)
  );
  const [started, setStarted] = useState(false);
  const [passedGoal, setPassedGoal] = useState(false);
  const [showCursorLine, setShowCursorLine] = useState(false);
  const [firstMoved, setFirstMoved] = useState(false);
  const fieldRef = useRef(null);
  const { getElementProperty: getFieldProps } =
    useGetElementProperty<HTMLDivElement>(fieldRef);

  const targetRefs = useRef<any>([]);

  const [cursorPosition, setCursorPosition] = useState<Point>(new Point(0, 0));
  const arrH = new Array(props.heightCount).fill(0);
  const arrW = new Array(props.widthCount).fill(0);

  const [linePosition, setLinePosition] = useState<Point>(props.startPosition);
  const [lineDOMPosition, setLineDOMPosition] = useState<Point>(
    props.startPosition
  );
  const [lineRotation, setLineRotation] = useState(0);
  const [lineWidth, setLineWidth] = useState(0);

  const [lines, setLines] = useState<Point[][]>([]);

  const [passedPoint, setPassedPoint] = useState<number[]>([]);
  const [correctLines, setCorrectLines] = useState<boolean[]>([]);

  const initialize = () => {
    const p: number[] = [];
    arrH.forEach((_, y) => {
      arrW.forEach((_, x) => {
        targetRefs.current[y * props.widthCount + x] = createRef<any>();
        const isStartPosition =
          props.startPosition.x == x && props.startPosition.y == y;
        p.push(isStartPosition ? 1 : 0);
      });
    });
    setPassedPoint(p);
    setPassedGoal(false);

    setTimeout(() => {
      setShowCursorLine(true);
      setStarted(true);
      setFirstMoved(false);
    }, 1000);
  };

  const getPointPosition = (pos: Point): Point | null => {
    if (targetRefs.current.length - 1 < pos.y * props.widthCount + pos.x)
      return null;

    const clientRect =
      targetRefs.current[
        pos.y * props.widthCount + pos.x
      ].current?.getBoundingClientRect();

    if (clientRect) {
      return new Point(
        clientRect.x + clientRect.width / 2,
        clientRect.y + clientRect.height / 2
      );
    } else {
      return null;
    }
  };

  const getDistange = (p1: Point, p2: Point): number => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const getDegree = (p1: Point, p2: Point): number => {
    return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
  };

  const mousemoveHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (started == false || props.finished) return;
    if (firstMoved == false) {
      setFirstMoved(true);
    }
    updateCursorLine(new Point(e.clientX, e.clientY), linePosition);
  };

  const mouseEnterHandler = () => {
    setShowCursorLine(true);
  };

  const mouseLeaveHandler = () => {
    setShowCursorLine(false);
  };

  const updateCursorLine = (mousePosition: Point, linePosition: Point) => {
    const offset = new Point(getFieldProps('x'), getFieldProps('y'));

    setCursorPosition(
      new Point(mousePosition.x - offset.x, mousePosition.y - offset.y)
    );

    const targetPos = getPointPosition(linePosition);

    if (targetPos == null) return;

    const p1 = new Point(targetPos.x - offset.x, targetPos.y - offset.y);
    const p2 = new Point(
      mousePosition.x - offset.x,
      mousePosition.y - offset.y
    );

    setLineDOMPosition(p1);

    const dist = getDistange(p1, p2);
    setLineWidth(dist);
    const rot = getDegree(p1, p2);
    setLineRotation(rot);
  };

  const clickPoint = (w: number, h: number) => {
    if (passedPoint[w + h * props.widthCount] > 0) return;
    if (passedGoal) return;

    const p1 = new Point(linePosition.x, linePosition.y);
    const p2 = new Point(w, h);

    setLines([...lines, [p1, p2]]);
    setLinePosition(new Point(w, h));
  };

  useImperativeHandle(ref, () => ({
    onUndo: () => {
      if (started == false || props.finished) return;

      if (lines.length > 0) {
        const newLines = [...lines];
        newLines.pop();
        setLines(newLines);

        if (newLines.length == 0) {
          setLinePosition(props.startPosition);
        } else {
          const lastPoint = newLines[newLines.length - 1];
          setLinePosition(lastPoint[1]);
        }
      }
    },
    onReset: () => {
      if (started == false || props.finished) return;
      setLines([]);
      setLinePosition(props.startPosition);
      const offset = new Point(getFieldProps('x'), getFieldProps('y'));
      updateCursorLine(
        new Point(cursorPosition.x + offset.x, cursorPosition.y + offset.y),
        props.startPosition
      );
      setPassedGoal(false);
      setShowCursorLine(true);
    },
    onResetAll: () => {
      setLines([]);
      setLinePosition(props.startPosition);
      const offset = new Point(getFieldProps('x'), getFieldProps('y'));
      updateCursorLine(
        new Point(cursorPosition.x + offset.x, cursorPosition.y + offset.y),
        props.startPosition
      );
      setStarted(false);
      setPassedGoal(false);
      initialize();
    },
  }));

  // 初期設定
  useEffect(() => {
    initialize();
  }, []);

  // CursorLineを描画する
  useEffect(() => {
    const offset = new Point(getFieldProps('x'), getFieldProps('y'));
    updateCursorLine(
      new Point(cursorPosition.x + offset.x, cursorPosition.y + offset.y),
      linePosition
    );
  }, [linePosition]);

  // 点を通った回数チェック
  useEffect(() => {
    const p: number[] = new Array(props.widthCount * props.heightCount);
    p.fill(0);

    // 開始地点
    p[props.startPosition.x + props.startPosition.y * props.widthCount]++;

    lines.forEach(single => {
      // クリックした点
      p[single[1].x + single[1].y * props.widthCount]++;

      // ２点の間にある点 WIP
      const passed: Point[] = [];
      if (
        Math.abs(single[1].x - single[0].x) > 1 ||
        Math.abs(single[1].y - single[0].y) > 1
      ) {
        // single[0]
        const dx = single[1].x - single[0].x;
        const dy = single[1].y - single[0].y;

        let kizami: Point | null = null;

        if (dx == 0) {
          // 垂直
          kizami = new Point(0, dy > 0 ? 1 : -1);
        } else if (dy == 0) {
          // 水平
          kizami = new Point(dx > 0 ? 1 : -1, 0);
        } else {
          // ななめ
          const g = gcd(dx, dy);
          if (dy < 0) {
            kizami = new Point(-(dx / g), -(dy / g));
          } else {
            kizami = new Point(dx / g, dy / g);
          }
        }

        // single[0]の座標にkizamiを足していく
        let i = 1;
        while (i < Math.max(props.widthCount, props.heightCount)) {
          const _p = new Point(
            single[0].x + kizami.x * i,
            single[0].y + kizami.y * i
          );

          if (_p.x == single[1].x && _p.y == single[1].y) {
            break;
          }
          if (
            _p.x > props.widthCount ||
            _p.y > props.heightCount ||
            _p.x < 0 ||
            _p.y < 0
          ) {
            break;
          }

          passed.push(_p);
          i++;
        }

        passed.forEach(single => {
          p[single.x + single.y * props.widthCount]++;
        });
      }
    });

    // 有効ではない点は1にする
    if (props.points != undefined) {
      props.points.forEach((w, y) =>
        w.forEach((h, x) => {
          if (h == false) {
            p[x + y * props.widthCount] = 1;
          }
        })
      );
    }

    setPassedPoint(p);

    // ライン条件チェック
    const correct = props.onCheck(lines);
    setCorrectLines(correct);

    // ゴールのチェック
    let _passedGoal = false;
    if (lines.length > 0) {
      const finalPoint = lines[lines.length - 1][1];
      if (
        finalPoint.x == props.goalPosition.x &&
        finalPoint.y == props.goalPosition.y
      ) {
        // ゴール
        let complete = true;
        correct.forEach(single => {
          if (single == false) complete = false;
        });
        props.onGoal(p, complete);
        _passedGoal = true;
      }
      setPassedGoal(_passedGoal);
    }
  }, [lines]);

  useEffect(() => {
    if (props.finished) {
      setFieldSizeAtFinished(new Point(getFieldProps('x'), getFieldProps('y')));
    }
  }, [props.finished]);

  return (
    <Frame
      style={{
        animationName: props.finished ? 'anim_finished' : 'anim_start',
        animationDuration: props.finished ? '0.5s' : '1.0s',
        animationTimingFunction: props.finished ? 'ease-in' : 'ease-out',
      }}
    >
      <StageNum>{props.question}</StageNum>
      <Wrapper
        onMouseMove={mousemoveHandler}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        ref={fieldRef}
      >
        {lines.map((single, l) => {
          const offset = props.finished
            ? fieldSizeAtFinished
            : new Point(getFieldProps('x'), getFieldProps('y'));

          let line_p1 = getPointPosition(single[0]);
          let line_p2 = getPointPosition(single[1]);

          if (line_p1 == null || line_p2 == null) {
            return;
          }

          line_p1 = new Point(line_p1.x - offset.x, line_p1.y - offset.y);
          line_p2 = new Point(line_p2.x - offset.x, line_p2.y - offset.y);

          const dist = getDistange(line_p1, line_p2);
          const deg = getDegree(line_p1, line_p2);

          return (
            <Line
              key={`line_${l}`}
              correct={correctLines[l]}
              style={{
                left: line_p1.x,
                top: line_p1.y,
                width: dist,
                transformOrigin: '0 center 0',
                transform: `rotate(${deg}deg)`,
              }}
            />
          );
        })}

        {showCursorLine &&
          started == true &&
          firstMoved == true &&
          props.finished == false &&
          passedGoal == false && (
            <CursorLine
              style={{
                left: lineDOMPosition.x,
                top: lineDOMPosition.y,
                width: lineWidth,
                transformOrigin: '0 center 0',
                transform: `rotate(${lineRotation}deg)`,
              }}
            />
          )}

        {arrH.map((_, h) => {
          return (
            <Col key={`col_${h}`}>
              {arrW.map((_, w) => {
                if (props.points != undefined) {
                  if (props.points[h][w] == false)
                    return (
                      <PointWrapper
                        key={`col_${h}_p_${w}`}
                        ref={targetRefs.current[h * props.widthCount + w]}
                      ></PointWrapper>
                    );
                }

                return (
                  <PointWrapper
                    key={`col_${h}_p_${w}`}
                    ref={targetRefs.current[h * props.widthCount + w]}
                  >
                    {props.startPosition.x == w &&
                    props.startPosition.y == h ? (
                      <StartPoint>
                        <img src={PointStart} />
                      </StartPoint>
                    ) : props.goalPosition.x == w &&
                      props.goalPosition.y == h ? (
                      <GoalPoint
                        finished={props.finished}
                        passage={passedPoint[h * props.widthCount + w]}
                        onClick={() => {
                          clickPoint(w, h);
                        }}
                      >
                        <img src={PointGoal} />
                      </GoalPoint>
                    ) : (
                      <ClickablePoint
                        finished={props.finished}
                        passage={passedPoint[h * props.widthCount + w]}
                        onClick={() => {
                          clickPoint(w, h);
                        }}
                      />
                    )}
                  </PointWrapper>
                );
              })}
            </Col>
          );
        })}
        {showCursorLine &&
          started == true &&
          firstMoved == true &&
          props.finished == false &&
          passedGoal == false && (
            <Cursor style={{ left: cursorPosition.x, top: cursorPosition.y }} />
          )}
      </Wrapper>
    </Frame>
  );
};

const StageNum = styled.div`
  background: #343a40;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CursorLine = styled.div`
  height: 4px;
  background: #707070;
  position: absolute;
  pointer-events: none;
`;

interface lineProps {
  correct: boolean;
}

const Line = styled.div`
  height: 4px;
  background: #868e96;
  position: absolute;
  pointer-events: none;
  transition: 0.2s;

  ${(p: lineProps) =>
    p.correct
      ? `
    background: #05AA70;
    box-shadow: 0px 0px 10px #05AA70;
  `
      : ``}
`;

const Frame = styled.div`
  z-index: 2;
  height: 600px;
  width: 600px;
  background: white;
  border: 1px solid #ced4da;
  display: block;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);

  @keyframes anim_finished {
    0% {
      transform: scale(1);
    }

    20% {
      transform: scale(0.95);
    }

    40% {
      transform: scale(1.06);
    }

    70% {
      transform: scale(0.99);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes anim_start {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Wrapper = styled.div`
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const PointWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PointBase = styled.div`
  width: 24px;
  height: 24px;
  background: #868e96;
  border-radius: 99px;
  z-index: 3;

  img {
    position: relative;
    left: 5px;
    top: 5px;
  }
`;

interface PointProps {
  passage: number;
  finished?: boolean;
}

const ClickablePoint = styled(PointBase)`
  position: relative;
  transition: 0.2s;

  &::before {
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 3px solid #868e96;
    border-radius: 99px;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  ${(p: PointProps) =>
    p.passage >= 1
      ? `
      &:before {
        display: none;
      }
    `
      : ``}

  ${(p: PointProps) =>
    p.passage >= 2
      ? `
        background: #D53220;
        &:before {
          border-color: #D53220;
        }
      `
      : ``}

      
  ${(p: PointProps) =>
    p.finished
      ? `
        background: #05AA70;
        box-shadow: 0px 0px 10px #05AA70;

      `
      : `
        &:hover {
          transform: scale(1.2);
        }
      `}
`;

const StartPoint = styled(PointBase)`
  background: #05aa70;
  width: 30px;
  height: 30px;
  box-shadow: 0px 0px 10px #05aa70;

  img {
    left: 7px;
    top: 7px;
    pointer-events: none;
  }
`;

const GoalPoint = styled(ClickablePoint)`
  background: #868e96;
  width: 30px;
  height: 30px;

  img {
    left: 7px;
    top: 7px;
    pointer-events: none;
  }

  ${(p: PointProps) =>
    p.finished
      ? `
        background: #05AA70;
        box-shadow: 0px 0px 10px #05AA70;
      `
      : ``}
`;

const Col = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Cursor = styled.div`
  width: 10px;
  height: 10px;
  background: white;
  border: 4px solid #707070;
  border-radius: 99px;
  position: absolute;
  transform: translate(-8px, -8px);
  pointer-events: none;
  z-index: 4;
`;

export default forwardRef(Field);

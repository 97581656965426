import {
  CommonBG,
  CountDown as _CountDown,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface CountDownProps {}
const CountDown: React.FC<CountDownProps> = props => {
  const location = useLocation();
  const [resultSet] = React.useState<any>(location.state);
  const { eventId } = useParams<'eventId'>();

  return (
    <CommonBG>
      <_CountDown redirect={`/events/${eventId}/game`} state={resultSet} />
    </CommonBG>
  );
};

export default CountDown;

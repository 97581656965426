import { app, db, functions } from '..';
import { SingleResult } from '../components/eventProvider/stagePages/Game';
import {
  sendStageEnd,
  sendStageScore,
  sendStageStart,
} from '@riddler-co-jp/specc-auth-client';
import { DocumentReference, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  DocumentDataHook,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

export type Game4 = {
  answers: SingleResult[];
  totalScore: number;
};

export type Stats = {
  rate: number[];
  averageScore: number;
};
type Maybe<T> = T | null;

const stageId = 'ct2energy';

export const startGame4 = (eventId: string, uid: string) =>
  sendStageStart(app, eventId, uid, stageId);

export const submitGame4 = (eventId: string, uid: string, result: Game4) => {
  const recordSubmissions = httpsCallable(functions, 'recordSubmissions');
  return Promise.allSettled([
    recordSubmissions({ eventId, result }),
    sendStageScore(app, eventId, uid, stageId, result.totalScore),
  ]);
};

export const finishGame4 = (eventId: string, uid: string) =>
  sendStageEnd(app, eventId, uid, stageId);

export const useStage4SubmissionDocData = (
  eventId?: Maybe<string>,
  userId?: Maybe<string>
): DocumentDataHook<Game4> => {
  const docRef =
    eventId != null && userId != null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          'users',
          userId
        ) as DocumentReference<Game4>)
      : undefined;
  return useDocumentData<Game4>(docRef);
};

export const useStatDocData = (
  eventId?: Maybe<string>
): DocumentDataHook<Stats> => {
  const docRef =
    eventId != null
      ? (doc(db, 'version/1/stats', eventId) as DocumentReference<Stats>)
      : undefined;
  return useDocumentData<Stats>(docRef);
};

// Copy of https://github.com/riddler-co-jp/dentsu-training-platform-server/blob/develop/functions/src/util/firestore/types.ts
// 本当はコピーじゃなくどうにかしたいけど...
import { EventStatus, GroupState } from '../types';
import { Timestamp } from 'firebase/firestore';

export type PublicDocument = {
  body: string;
};

export type Game1 = {
  answers: {
    achieved: number[];
    answer: string;
    // createdOn: Timestamp;
  }[];
};

export type Game2Info = {
  answers: {
    answer: string[];
    createdOn: Timestamp;
    correct: boolean;
  }[];
  infoId: number;
  // MEMO: server#102
};

export type Game2 = {
  finalAnswer: string;
  createdOn: Timestamp;
  correct: boolean;
};

export type Game3 = {
  answers: {
    answer: string;
    createdOn: Timestamp;
    correct: boolean;
  }[];
  problemId: number;
};

export type Game4 = {
  penalty: number;
};

export type Game4StageRecord = {
  clearedIn: number;
  problemId: number;
};

export type Game5 = {
  acceptingAnswer: boolean;
  createdBy: string;
  currentProblemId: number | null;
  currentProblemNumber: number | null;
  members: string[];
  presenter: string | null;
  searchId: number;
  state: GroupState;
  activeMembers: string[];
  updatedAt: Timestamp;
  problemStartedAt: Timestamp | null;
};

export type Game5Problem = {
  setBy: string;
  problemId: number;
};

export type Game5Answer = {
  answer: string;
  createdOn: Timestamp;
  problemId: number;
};

export type Event = {
  status: EventStatus;
  name: string;
  scheduledStartTime: Timestamp;
  scheduledEndTime: Timestamp;
  place: string | null;
  startedOn: Timestamp | null;
  endedOn: Timestamp | null;
  resultCalculatedOn: Timestamp | null;
  orgId: string;
};

export type EventMember = {
  owners: string[];
  participants: string[];
  orgId: string;
};

export type TrainingMember = {
  participants: string[];
  orgId: string;
};

export type UserScore = {
  score: number | null;
};
export type UserScores = {
  stage1: UserScore;
  stage2: UserScore;
  stage3: UserScore;
  stage4: UserScore;
  stage5: UserScore;
  total: UserScore;
};
export type UserRecords = {
  calculatedScores: UserScores;
  startTimes1?: Timestamp | null;
  startTimes2?: Timestamp | null;
  startTimes3?: Timestamp | null;
  startTimes4?: Timestamp | null;
  startTimes5?: Timestamp | null;
};

export type StatValue = {
  avg: number | null;
  std: number | null;
};

export type EventStats = {
  orgId: string;
  stage1: StatValue;
  stage2: StatValue;
  stage3: StatValue;
  stage4: StatValue;
  stage5: StatValue;
  total: StatValue;
};

export type UserInfo = {
  commonRoles?: string[];
  orgAdmin?: string[];
  fullName: string;
  ruby: string | null;
  birthday: Timestamp | null;
  email?: string;
  displayName?: string;
  createdOn: Timestamp | null;
  mainOrgId: string;
};

export type UserOrgInfo = {
  startYear: number;
  department: string | null;
  employeeId: string | null;
  industryId?: string | null;
  occupationId?: string | null;
  position: string | null;
};

export type Organization = {
  name: string;
  admin: string[];
  members: string[];
  players: string[];
  createdOn: Timestamp | null;
  enable: boolean;
  imgSrc: string | null;
  curriculums: string[] | null;
};

export type ErrorRecord = {
  userID: string;
  errorCode: string;
  storeDump: string;
  userAgent: string;
  eventId: string | null;
  message: string | null;
  stackTrace: string | null;
  gqlErrors:
    | {
        locations: { column: number; line: number }[] | null;
        code: string | null;
        message: string;
        path: string[] | null;
      }[]
    | null;
  gqlPayload: string | null;
  createdOn: Timestamp | null;
};

export type RegistrationToken = {
  token: string;
  expiresAt: Timestamp;
  createdBy: string;
  createdOn: Timestamp;
  shared: boolean;
  expectedEmailRegex: string;
  orgId: string;
  disabled: boolean;
  adminRole: string | null;
};
export const roleType = {
  Admin: 'admin',
  Player: 'player',
} as const;
export type RoleType = typeof roleType[keyof typeof roleType];

export const commonRoleType = {
  RiddlerAdmin: 'riddlerAdmin',
  DentsuAdmin: 'dentsuAdmin',
} as const;
export type CommonRoleType = typeof commonRoleType[keyof typeof commonRoleType];
export interface CustomClaimContent {
  roles: Record<string, RoleType[]>;
  commonRoles: CommonRoleType[];
}

export const isCustomClaimContentLike = (
  x: unknown
): x is Record<keyof CustomClaimContent, unknown> => {
  return (
    typeof x === 'object' && x !== null && 'roles' in x && 'commonRoles' in x
  );
};
export const isCustomClaimContent = (x: unknown): x is CustomClaimContent => {
  return (
    isCustomClaimContentLike(x) &&
    typeof x.roles === 'object' &&
    x.roles !== null &&
    x.commonRoles !== null
  );
};

export const EPS = 1e-9;

declare global {
  interface Number {
    eq(y: number): boolean;
    sign(): number;
    in(a: number, b: number): boolean;
  }
}

/**
 * 呼び出し元の値と引数が等しいかを判定します
 * 浮動小数点誤差に対応しています。
 * @param y 比較する値
 */
Number.prototype.eq = function (y: number): boolean {
  const x: number = this.valueOf();
  return Math.abs(x - y) < EPS;
};

/**
 * 呼び出し元の値の符号を求めます。浮動小数点誤差に対応しています。
 * @returns 正ならば1, 負ならば -1, 0に近ければ 0
 */
Number.prototype.sign = function (): number {
  const x: number = this.valueOf();
  if (x > EPS) {
    return 1;
  }
  if (x < EPS) {
    return -1;
  }
  return 0;
};

/**
 * 呼び出し元の値が開区間aとbの間に含まれるかどうかを判定します
 * 浮動小数点誤差に対応しています。
 * @param a min
 * @param b max
 */
Number.prototype.in = function (a: number, b: number): boolean {
  const x: number = this.valueOf();
  return a + EPS < x && x < b - EPS;
};

/**
 * 2次元座標クラス
 */
export class Point {
  /**
   * x座標
   */
  x: number;
  /**
   * y座標
   */
  y: number;
  /**
   * @param x x座標
   * @param y y座標
   */
  constructor(x?: number, y?: number) {
    if (x == null) {
      x = 0;
    }
    if (y == null) {
      y = 0;
    }
    this.x = x;
    this.y = y;
  }

  /**
   * 大小関係を比較します,
   * 第一キー: x座標, 第二キー: y座標
   * @param p 比較される座標
   */
  lt(p: Point) {
    if (this.eq(p)) {
      return 0;
    } else {
      if (this.x.eq(p.x)) {
        return this.y - p.y;
      } else {
        return this.x - p.x;
      }
    }
  }
  /**
   * 絶対値を求めます
   */
  abs(): number {
    return Math.sqrt(this.norm());
  }
  /**
   * 絶対値の二乗を求めます
   */
  norm(): number {
    return this.x * this.x + this.y * this.y;
  }
  /**
   * 偏角を求めます
   */
  arg(): number {
    return Math.atan2(this.y, this.x);
  }
  /**
   * x座標を実部, y座標を虚部と見たときに共役な座標を返します
   */
  conj(): Point {
    return new Point(this.x, -this.y);
  }
  /**
   * 引数と等しいかを比較します
   * @param point
   */
  eq(point: Point): boolean {
    return this.x.eq(point.x) && this.y.eq(point.y);
  }
  /**
   * 引数を加えた座標を求めます
   * @param point
   */
  plus(point: Point): Point {
    return new Point(this.x + point.x, this.y + point.y);
  }
  /**
   * 引数を引いた座標を求めます
   * @param point
   */
  minus(point: Point): Point {
    return new Point(this.x - point.x, this.y - point.y);
  }
  /**
   * 引数との内積を求めます
   * @param point
   */
  dot(point: Point): number {
    return this.x * point.x + this.y * point.y;
  }
  /**
   * 引数との外積を求めます
   * @param point
   */
  cross(point: Point): number {
    return this.x * point.y - this.y * point.x;
  }
  /**
   * 引数との中点を求めます
   * @param point
   */
  midPoint(point: Point): Point {
    return new Point((this.x + point.x) / 2, (this.y + point.y) / 2);
  }
  /**
   * 原点を中心にangleだけ回転させた座標を求めます
   * @param angle 回転角(ラジアン)
   */
  rotate(angle: number): Point {
    return this.mult(Pt(Math.cos(angle), Math.sin(angle)));
  }
  /**
   * 引数を乗算します
   * 引数がPoint型の場合は複素数の乗算として扱います。
   * @param obj 乗数
   */
  mult(obj: Point | number): Point {
    if (obj instanceof Point) {
      return new Point(
        this.x * obj.x - this.y * obj.y,
        this.y * obj.x + this.x * obj.y
      );
    }
    return new Point(this.x * obj, this.y * obj);
  }
  /**
   * 引数で除算します
   * @param obj 除数
   */
  div(obj: Point | number): Point {
    if (obj instanceof Point) {
      return new Point(
        obj.dot(this) / obj.norm(),
        obj.cross(this) / obj.norm()
      );
    }
    return this.mult(1 / obj);
  }

  /**
   * 引数の直線に向かって垂直に下ろした座標を返します
   * @param line
   */
  projectTo(line: LineModel) {
    const t =
      this.minus(line.points[1]).dot(line.vector()) *
      (1 / line.vector().norm());
    return line.points[1].plus(line.vector().mult(t));
  }
  /**
   * 引数の直線を軸に点対称な座標を返します
   * @param line
   */
  lineSymmetryBy(line: LineModel) {
    return this.plus(this.projectTo(line).minus(this).mult(2));
  }
}

/**
 * 2次元面積クラス(Pointのラッパー)
 */
class SizeModel extends Point {
  constructor(width: number, height: number) {
    super(width, height);
  }
  /**
   * 幅
   */
  get width() {
    return this.x;
  }
  set width(val: number) {
    this.width = val;
  }
  /**
   * 高さ
   */
  get height() {
    return this.y;
  }
  set height(val: number) {
    this.y = val;
  }
}

/**
 * 直線クラス
 */
export class LineModel {
  points: Array<Point>;
  constructor(pos1: Point, pos2: Point) {
    this.points = [pos1, pos2];
  }
  /**
   * ベクトル座標
   */
  vector(): Point {
    return this.points[1].minus(this.points[0]);
  }

  //直線に対する点の位置
  ccw(point: Point) {
    const b = this.vector();
    const c = point.minus(this.points[0]);
    const length = b.abs() * c.abs();
    if (b.cross(c) > length * EPS) {
      return 1;
    }
    if (b.cross(c) < -length * EPS) {
      return -1;
    }
    if (b.dot(c) < 0) {
      return 2;
    }
    if (b.norm() < c.norm()) {
      return -2;
    }
    return 0;
  }
  /**
   * 2本の直線が平行かを判定します
   * @param line
   */
  paralell(line: LineModel) {
    return this.vector().cross(line.vector()).sign() === 0;
  }
  /**
   * 2本の直線が等しいかを判定します
   * @param line
   */
  same(line: LineModel) {
    return (
      this.vector().cross(this.points[0].minus(line.points[0])).sign() === 0
    );
  }
  /**
   * 直線と線分、もしくは直線と座標が交点を持つかを判定します
   * @param object
   */
  intersect(object: LineSegment | Point) {
    if (object instanceof LineSegment) {
      const seg = object as LineSegment;
      return (
        this.vector().cross(seg.points[0].minus(this.points[0])).sign() *
          this.vector().cross(seg.points[1].minus(this.points[0])) <=
        0
      );
    }
    if (object instanceof Point) {
      return this.vector().cross(object.minus(this.points[0])).sign() === 0;
    }
    return undefined;
  }
  /**
   * 2本の直線の交点を求めます
   * @param line
   */
  crossPoint(line: LineModel) {
    const a = this.vector().cross(line.vector());
    const b = this.vector().cross(this.points[1].minus(line.points[0]));
    if (Math.abs(a) < EPS && Math.abs(b) < EPS) {
      return line.points[0];
    }
    if (Math.abs(a) < EPS) {
      return null;
    }
    return line.points[0].plus(line.vector().mult(b / a));
  }
}

/**
 * 円クラス
 */
export class Circle {
  /**
   * 中心座標
   */
  center: Point;
  /**
   * 半径
   */
  radius: number;
  /**
   * 中心座標と半径から円を生成します
   * @param center 中心座標
   * @param radius 半径
   */
  constructor(center: Point, radius: number) {
    this.center = center;
    this.radius = radius;
  }
  /**
   * 3点を通る円を生成します
   * @param p1
   * @param p2
   * @param p3
   */
  static createFor3points(p1: Point, p2: Point, p3: Point) {
    const dist1 = p1.norm();
    const dist2 = p2.norm();
    const dist3 = p3.norm();
    const u =
      0.5 /
      (p1.x * p2.y -
        p2.x * p1.y +
        p2.x * p3.y -
        p3.x * p2.y +
        p3.x * p1.y -
        p1.x * p3.y);
    const center = new Point(0, 0);
    center.x =
      u *
      (dist1 * p2.y -
        dist2 * p1.y +
        dist2 * p3.y -
        dist3 * p2.y +
        dist3 * p1.y -
        dist1 * p3.y);
    center.y =
      u *
      (p1.x * dist2 -
        p2.x * dist1 +
        p2.x * dist3 -
        p3.x * dist2 +
        p3.x * dist1 -
        p1.x * dist3);
    const r = center.minus(p1).abs();
    return new Circle(center, r);
  }
  /**
   * 2点と半径から決まる円のリストを返します
   * @param p1
   * @param p2
   * @param r
   */
  static createFor2pointsAndRadius(p1: Point, p2: Point, r: number) {
    const m = p1.midPoint(p2);
    const vec = p2.minus(p1);
    const len = vec.abs();
    if (len > 2 * r + EPS) {
      return [];
    }
    if (len.eq(2 * r)) {
      return [new Circle(m, r)];
    }
    const x = Math.sqrt(r * r - (len / 2) * (len / 2));
    const c1 = vec.mult(Pt(0, x / vec.abs())).plus(m);
    const c2 = vec.mult(Pt(0, -x / vec.abs())).plus(m);
    const circles = [];
    circles.push(new Circle(c1, r));
    circles.push(new Circle(c2, r));
    return circles;
  }
  /**
   * 引数から円を生成します
   * @param obj1
   * @param obj2
   * @param obj3
   */
  static create(obj1: Point, obj2: Point, obj3: Point | number) {
    if (
      obj1 instanceof Point &&
      obj2 instanceof Point &&
      obj3 instanceof Point
    ) {
      return Circle.createFor3points(obj1, obj2, obj3);
    }
    if (
      obj1 instanceof Point &&
      obj2 instanceof Point &&
      typeof obj3 === 'number'
    ) {
      return Circle.createFor2pointsAndRadius(obj1, obj2, obj3);
    }
    return null;
  }
  /**
   * 点が円に含まれるかを判定します
   * @param point
   */
  contain(point: Point) {
    return point.minus(this.center).abs() < this.radius;
  }
}
/**
 * 扇型クラス
 */
export class Sector {
  center: Point;
  radius: number;
  startAngle: number;
  angleSize: number;
  anticlockwise: boolean;
  constructor(
    center: Point,
    radius: number,
    startAngle: number,
    angleSize: number,
    anticlockwise: boolean
  ) {
    this.center = center;
    this.radius = radius;
    this.startAngle = startAngle;
    this.angleSize = angleSize;
    if (anticlockwise == null) {
      anticlockwise = true;
    }
    this.anticlockwise = anticlockwise;
  }
}

/**
 * 長方形クラス
 * x軸, y軸に平行な長方形
 */
export class Rectangle {
  /**
   * 頂点座標リスト
   */
  points: Array<Point>;
  /**
   * x,yが最も小さい座標(画面では左上, デカルト座標形では左下)を返します
   */
  min: Point;
  /**
   * x,yが最も大きい座標(画面では右下, デカルト座標形では右上)を返します
   */
  max: Point;
  /**
   * 指定した2点を頂点とするx軸, y軸に平行な長方形のコンストラクタ
   * @param p1
   * @param p2
   */
  constructor(p1: Point, p2: Point) {
    this.points = [];
    this.points.push(new Point(p1.x, p1.y));
    this.points.push(new Point(p2.x, p1.y));
    this.points.push(new Point(p2.x, p2.y));
    this.points.push(new Point(p1.x, p2.y));
    this.min = new Point(Math.min(p1.x, p2.x), Math.min(p1.y, p2.y));
    this.max = new Point(Math.max(p1.x, p2.x), Math.max(p1.y, p2.y));
  }
  /**
   * ある点が長方形に含まれるかを判定します
   * @param p
   */
  contain(p: Point) {
    return p.x.in(this.min.x, this.max.x) && p.y.in(this.min.y, this.max.y);
  }
}

/**
 * 多角形クラス
 */
class Polygonal {
  points: Array<Point>;
  static initClass() {
    this.prototype.points = [];
  }
  constructor(points: Array<Point>) {
    this.points = points;
  }
}
Polygonal.initClass();

/**
 * パスクラス
 * 座標のリストを順に辿るパス
 */
export class Path {
  /**
   * 座標のリスト
   */
  points: Array<Point>;
  constructor(points: Array<Point>) {
    this.points = points;
  }
  /**
   * パスの基準となる座標、最初の座標を返します
   */
  base() {
    return this.points[0];
  }

  /**
   * 座標のリストを反転させたパスを返します
   */
  reverse() {
    return new Path(this.points.reverse());
  }

  /**
   * 2つのパスが等しいかを判定します
   * @param path
   */
  eq(path: Path) {
    for (let index = 0; index < this.points.length; index++) {
      const point = this.points[index];
      if (!point.eq(path.points[index])) {
        return false;
      }
    }
    return true;
  }
  /**
   * 基準点の位置を固定したままパスを拡大縮小します
   * @param scale
   */
  scale(scale: number) {
    if (scale == null) {
      scale = 1;
    }
    const points = [this.base()];
    for (
      let i = 1, end = this.points.length, asc = 1 <= end;
      asc ? i < end : i > end;
      asc ? i++ : i--
    ) {
      points.push(
        this.points[i].minus(this.base()).mult(scale).plus(this.base())
      );
    }
    return new Path(points);
  }
  /**
   * 基準点の座標を固定したままパスを回転させます
   * @param angle 回転角(ラジアン)
   */
  rotate(angle: number) {
    if (angle == null) {
      angle = 0;
    }
    const points = [this.base()];
    for (
      let i = 1, end = this.points.length, asc = 1 <= end;
      asc ? i < end : i > end;
      asc ? i++ : i--
    ) {
      points.push(
        this.points[i].minus(this.base()).rotate(angle).plus(this.base())
      );
    }
    return new Path(points);
  }

  trancelation(position: Point) {
    if (position == null) {
      position = Pt(0, 0);
    }
    const points = [];
    for (const point of Array.from(this.points)) {
      points.push(point.plus(position));
    }
    return new Path(points);
  }
  /**
   * 指定した直線を軸に線対象な
   * 直線を指定しないとパスの1番目と2番目を軸に線対象なパスを返します
   * @param line
   */
  lineSymmetryBy(line?: LineModel) {
    if (line == null) {
      line = new LineModel(this.points[0], this.points[1]);
    }
    const points = [];
    for (const point of Array.from(this.points)) {
      points.push(point.lineSymmetryBy(line));
    }
    return new Path(points);
  }
  /**
   * パスの1番目と2番目を軸に線対象なパスを返します
   */
  lineSymmetry() {
    return this.lineSymmetryBy();
  }

  /**
   * 2つのパスが方向付きで合同かを判定します
   * @param path 判定するパス
   * @param symmetry 反転を許容
   * @param rotate 回転を許容
   */
  directedCongruent(path: Path, symmetry: boolean, rotate: boolean) {
    if (symmetry == null) {
      symmetry = true;
    }
    if (rotate == null) {
      rotate = true;
    }
    path = path.trancelation(this.base().minus(path.base()));
    if (rotate) {
      const vec1 = this.points[1].minus(this.base());
      const vec2 = path.points[1].minus(path.base());
      const angle = Math.acos(vec1.dot(vec2) / (vec1.abs() * vec2.abs()));
      path = path.rotate(angle);
    }

    if (symmetry) {
      return this.eq(path) || this.eq(path.lineSymmetry());
    } else {
      return this.eq(path);
    }
  }
  /**
   * 2つのパスが方向付きで相似かを判定します
   * @param path 判定するパス
   * @param symmetry 反転を許容
   * @param rotate 回転を許容
   */
  directedSimilar(path: Path, symmetry: boolean, rotate: boolean) {
    if (symmetry == null) {
      symmetry = true;
    }
    if (rotate == null) {
      rotate = true;
    }
    const scale =
      this.points[1].minus(this.points[0]).abs() /
      path.points[1].minus(path.points[0]).abs();
    return this.directedCongruent(path.scale(scale), symmetry, rotate);
  }

  /**
   * 2つのパスが合同かを判定します
   * @param path 判定するパス
   * @param symmetry 反転を許容
   * @param rotate 回転を許容
   */
  congruent(path: Path, symmetry: boolean, rotate: boolean, direct: boolean) {
    if (symmetry == null) {
      symmetry = true;
    }
    if (rotate == null) {
      rotate = true;
    }
    if (direct == null) {
      direct = false;
    }
    if (direct) {
      return this.directedCongruent(path, symmetry, rotate);
    } else {
      return (
        this.directedCongruent(path, symmetry, rotate) ||
        this.directedCongruent(path.reverse(), symmetry, rotate)
      );
    }
  }
  /**
   * 2つのパスが相似かを判定します
   * @param path 判定するパス
   * @param symmetry 反転を許容
   * @param rotate 回転を許容
   * @param direct 方向を考慮
   */
  similar(path: Path, symmetry: boolean, rotate: boolean, direct: boolean) {
    if (symmetry == null) {
      symmetry = true;
    }
    if (rotate == null) {
      rotate = true;
    }
    if (direct == null) {
      direct = false;
    }
    if (direct) {
      return this.directedSimilar(path, symmetry, rotate);
    } else {
      return (
        this.directedSimilar(path, symmetry, rotate) ||
        this.directedSimilar(path.reverse(), symmetry, rotate)
      );
    }
  }
  /**
   * 2つのパスが反転を許容せず回転合同かを判定します
   * @param path
   * @param direct 方向を考慮
   */
  onesidedCongruent(path: Path, direct: boolean) {
    if (direct == null) {
      direct = false;
    }
    return this.congruent(path, false, true, direct);
  }
  /**
   * 2つのパスが反転を許容せず回転合同かを判定します
   * @param path
   * @param direct 方向を考慮
   */
  onesidedSimilar(path: Path, direct: boolean) {
    if (direct == null) {
      direct = false;
    }
    return this.similar(path, false, true, direct);
  }
  /**
   * 2つのパスが回転・反転を許容せず合同かを判定します
   * @param path
   * @param direct 方向を考慮
   */
  fixedCongruent(path: Path, direct: boolean) {
    if (direct == null) {
      direct = false;
    }
    return this.congruent(path, false, false, direct);
  }
  /**
   * 2つのパスが回転・反転を許容せず相似かを判定します
   * @param path
   * @param direct 方向を考慮
   */
  fixedSimilar(path: Path, direct: boolean) {
    if (direct == null) {
      direct = false;
    }
    return this.similar(path, false, false, direct);
  }
}

/**
 * 線分オブジェクト
 */
export class LineSegment extends Path {
  constructor(pos1: Point, pos2: Point) {
    super([pos1, pos2]);
  }
  /**
   * ベクトル座標
   */
  vector(): Point {
    return this.points[1].minus(this.points[0]);
  }
  /**
   * 線分の長さ
   */
  size(): number {
    return this.vector().abs();
  }
  /**
   * 単位ベクトル座標
   */
  unitVector() {
    return this.vector().div(this.size());
  }

  /**
   * 直線に対する点の位置
   */
  ccw(point: Point) {
    const b = this.vector();
    const c = point.minus(this.points[0]);
    const length = b.abs() * c.abs();
    if (b.cross(c) > length * EPS) {
      return 1;
    }
    if (b.cross(c) < -length * EPS) {
      return -1;
    }
    if (b.dot(c) < 0) {
      return 2;
    }
    if (b.norm() < c.norm()) {
      return -2;
    }
    return 0;
  }

  intersect(object: Point | LineSegment) {
    if (object instanceof Point) {
      return this.ccw(object) === 0;
    }
    if (object instanceof LineSegment) {
      return (
        this.ccw(object.points[0]) * this.ccw(object.points[1]) <= 0 &&
        object.ccw(this.points[0]) * object.ccw(this.points[1]) <= 0
      );
    }
    return undefined;
  }

  innerIntersect(object: Point | LineSegment) {
    if (object instanceof Point) {
      return (
        this.ccw(object) === 0 &&
        !object.eq(this.points[0]) &&
        !object.eq(this.points[1])
      );
    }
    if (object instanceof LineSegment) {
      const cp = this.crossPoint(object);
      return (
        this.ccw(object.points[0]) * this.ccw(object.points[1]) <= 0 &&
        object.ccw(this.points[0]) * object.ccw(this.points[1]) <= 0 &&
        !cp?.eq(this.points[0]) &&
        !cp?.eq(this.points[1]) &&
        !cp?.eq(object.points[0]) &&
        !cp?.eq(object.points[1])
      );
    }
    return undefined;
  }

  crossPoint(line: LineSegment) {
    if (!this.intersect(line)) {
      return null;
    }
    const a = this.vector().cross(line.vector());
    const b = this.vector().cross(this.points[1].minus(line.points[0]));
    if (Math.abs(a) < EPS && Math.abs(b) < EPS) {
      return line.points[0];
    }
    if (Math.abs(a) < EPS) {
      return null;
    }
    return line.points[0].plus(line.vector().mult(b / a));
  }

  midPoint() {
    return this.points[0].midPoint(this.points[1]);
  }
}

/**
 * ベクトル(方向付き線分)クラス
 */
class Vector extends LineSegment {
  origin: Point;
  constructor(origin: Point, vec: Point) {
    super(origin, origin.plus(vec));
    this.origin = origin;
  }
}

/**
 * 関数クラス
 */
class FunctionGraph {
  func: (x: number) => number;
  constructor(func: (x: number) => number) {
    this.func = func;
  }
}

/**
 * x,y座標から座標オブジェクトを生成します
 * @param x
 * @param y
 */
export const Pt = (x: number, y: number): Point => new Point(x, y);
/**
 * 極座標から座標オブジェクトを生成します
 * @param r 半径
 * @param t 偏角θ
 */
export const Polar = (r: number, t: number) =>
  new Point(r * Math.cos(t), r * Math.sin(t));
/**
 * 引数から円を生成します
 * @param arg0
 * @param arg1
 * @param arg2
 */
export const Cir = function (
  arg0: number | Point,
  arg1: number | Point,
  arg2?: number
) {
  if (typeof arg0 === 'number') {
    return new Circle(Pt(arg0, arg1 as number), arg2 as number);
  } else {
    return new Circle(arg0, arg1 as number);
  }
};
/**
 * 扇型オブジェクトを生成します
 * @param center 中心座標
 * @param radius 半径
 * @param startAngle 開始角度
 * @param angleSize 中心角
 * @param anticlockwise 反時計回りか
 */
export const Sect = function (
  center: Point,
  radius: number,
  startAngle: number,
  angleSize: number,
  anticlockwise?: boolean
) {
  if (anticlockwise == null) {
    anticlockwise = true;
  }
  return new Sector(center, radius, startAngle, angleSize, anticlockwise);
};
/**
 * 線分オブジェクトを生成します
 * @param p1
 * @param p2
 */
export const Seg = (p1: Point, p2: Point) => new LineSegment(p1, p2);
/**
 * 直線オブジェクトを生成します
 * @param p1
 * @param p2
 */
export const Line = (p1: Point, p2: Point) => new LineModel(p1, p2);
/**
 * 2点を頂点とするx,y軸に平行な長方形オブジェクトを生成します
 * @param p1
 * @param p2
 */
export const Rect = (p1: Point, p2: Point) => new Rectangle(p1, p2);
/**
 * 多角形オブジェクトを生成します
 * @param points 頂点座標のリスト
 */
export const Poly = (points: Array<Point>) => new Polygonal(points);
/**
 * ベクトルを生成します
 * @param origin
 * @param vec
 */
export const Vec = (origin: Point, vec: Point) => new Vector(origin, vec);
/**
 * サイズオブジェクトを生成します
 * @param width 横幅
 * @param height 縦幅
 */
export const Size = (width: number, height: number) =>
  new SizeModel(width, height);
/**
 * 関数グラフオブジェクトを生成します
 * @param func
 */
export const Func = (func: (x: number) => number) => new FunctionGraph(func);
const Origin = Pt(0, 0);

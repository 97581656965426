import { Point } from '../components/uiElements/Field';
import Ans1 from '../static/png/answers/ans1.png';
import Ans2 from '../static/png/answers/ans2.png';
import Ans3 from '../static/png/answers/ans3.png';
import Ans4 from '../static/png/answers/ans4.png';
import Ans5 from '../static/png/answers/ans5.png';
import Ans6 from '../static/png/answers/ans6.png';
import Ans7 from '../static/png/answers/ans7.png';
import Ans8 from '../static/png/answers/ans8.png';
import Hint1_1 from '../static/png/hint/Q1-1.jpg';
import Hint1_2 from '../static/png/hint/Q1-2.jpg';
import Hint2_1 from '../static/png/hint/Q2-1.jpg';
import Hint2_2 from '../static/png/hint/Q2-2.jpg';
import Hint3_1 from '../static/png/hint/Q3-1.jpg';
import Hint3_2 from '../static/png/hint/Q3-2.jpg';
import Hint4_1 from '../static/png/hint/Q4-1.jpg';
import Hint4_2 from '../static/png/hint/Q4-2.jpg';
import Hint5_1 from '../static/png/hint/Q5-1.jpg';
import Hint5_2 from '../static/png/hint/Q5-2.jpg';
import Hint5_3 from '../static/png/hint/Q5-3.jpg';
import Hint5_4 from '../static/png/hint/Q5-4.jpg';
import Hint6_1 from '../static/png/hint/Q6-1.jpg';
import Hint6_2 from '../static/png/hint/Q6-2.jpg';
import Hint6_3 from '../static/png/hint/Q6-3.jpg';
import Hint6_4 from '../static/png/hint/Q6-4.jpg';
import Hint6_5 from '../static/png/hint/Q6-5.jpg';
import Hint7_1 from '../static/png/hint/Q7-1.jpg';
import Hint7_2 from '../static/png/hint/Q7-2.jpg';
import Hint7_3 from '../static/png/hint/Q7-3.jpg';
import Hint8_1 from '../static/png/hint/Q8-1.jpg';
import Hint8_2 from '../static/png/hint/Q8-2.jpg';
import Hint8_3 from '../static/png/hint/Q8-3.jpg';
import { LineSegment, Pt, Seg } from './geometry';

// 基準（0,0）は左上

export interface SingleStage {
  stage: number;
  answer: string;
  correctImage: string;
  width: number;
  height: number;
  startPosition: Point;
  goalPosition: Point;
  onCheck: (lines: Point[][]) => boolean[];
  hintRule: SingleHint[];
  hintMethod: SingleHint[];
  points?: boolean[][];
}
export interface SingleHint {
  title: string;
  text: string;
  image: string | null;
}

export const stages: SingleStage[] = [
  {
    stage: 1,
    answer: 'タテヨコ移動をせずにゴールに向かう',
    correctImage: Ans1,
    width: 3,
    height: 3,
    startPosition: new Point(0, 2),
    goalPosition: new Point(2, 2),
    onCheck: (lines: Point[][]): boolean[] => {
      return lines.map(single => {
        if (single[0].x == single[1].x && single[0].y != single[1].y) {
          return false;
        }
        if (single[0].x != single[1].x && single[0].y == single[1].y) {
          return false;
        }
        return true;
      });
    },
    hintRule: [
      {
        title: 'ルールのヒント',
        text: 'タテヨコに移動すると光らないようです。タテヨコ移動をせずにゴールを目指しましょう。',
        image: Hint1_1,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント',
        text: '最初にSから右上すみの点まで線を引きましょう。そうすると方針が見えてくるはずです。',
        image: Hint1_2,
      },
    ],
  },
  {
    stage: 2,
    answer: 'それぞれの線が１回以上交差する',
    correctImage: Ans2,
    width: 3,
    height: 3,
    startPosition: new Point(0, 2),
    goalPosition: new Point(2, 2),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      if (ls.length === 0) {
        return [];
      }
      const paths: LineSegment[] = [];
      const pathMapping = [];
      let tempPath = [];
      let pathId = 0;
      pathMapping.push(0);
      tempPath.push(ls[0]);
      for (let i = 1; i < ls.length; ++i) {
        if (
          !tempPath[tempPath.length - 1].unitVector().eq(ls[i].unitVector())
        ) {
          paths.push(
            Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
          );
          ++pathId;
          tempPath = [];
        }
        pathMapping.push(pathId);
        tempPath.push(ls[i]);
      }
      paths.push(
        Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
      );
      const check = paths.map((la, i) =>
        paths.some((lb, j) => i !== j && la.innerIntersect(lb))
      );
      return pathMapping.map(e => check[e]);
    },
    hintRule: [
      {
        title: 'ルールのヒント',
        text: '線が交差すると光るようです。全ての線が交差するように線を引きましょう。',
        image: Hint2_1,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント',
        text: 'できるだけナナメに線を繋いでいくとうまくいきます。',
        image: Hint2_2,
      },
    ],
  },
  {
    stage: 3,
    answer: '長さ１or２の線分だけでゴールする',
    correctImage: Ans3,
    width: 5,
    height: 5,
    startPosition: new Point(0, 4),
    goalPosition: new Point(4, 4),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      if (ls.length === 0) {
        return [];
      }
      const paths: LineSegment[] = [];
      const pathMapping = [];
      let tempPath = [];
      let pathId = 0;
      pathMapping.push(0);
      tempPath.push(ls[0]);
      for (let i = 1; i < ls.length; ++i) {
        if (
          !tempPath[tempPath.length - 1].unitVector().eq(ls[i].unitVector())
        ) {
          paths.push(
            Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
          );
          ++pathId;
          tempPath = [];
        }
        pathMapping.push(pathId);
        tempPath.push(ls[i]);
      }
      paths.push(
        Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
      );

      const check = paths.map(line => [1, 2].some(e => line.size().eq(e)));
      return pathMapping.map(e => check[e]);
    },
    hintRule: [
      {
        title: 'ルールのヒント',
        text: '線の長さが鍵になっています。',
        image: Hint3_1,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント',
        text: '長さが �、� の線だけでゴールしましょう',
        image: Hint3_2,
      },
    ],
  },
  {
    stage: 4,
    answer: 'ひとつ右隣にある列の点に(右端の点は左端の列の点に)線を引く',
    correctImage: Ans5,
    width: 4,
    height: 4,
    startPosition: new Point(0, 3),
    goalPosition: new Point(3, 3),
    onCheck: (lines: Point[][]): boolean[] => {
      return lines.map(line => {
        return (
          ((line[0].x + 1) % 4 === line[1].x && line[0].y !== line[1].y) ||
          (line[0].y === line[1].y && line[0].x < line[1].x)
        );
      });
    },
    hintRule: [
      {
        title: 'ルールのヒント',
        text: '点をタテ列ごとに考えましょう。',
        image: Hint5_1,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント',
        text: 'ある点から引かれる線は、その右隣の列にある点（最も右の列にある点は左端の列の点）に結んでいきましょう。',
        image: Hint5_2,
      },
    ],
  },
  {
    stage: 5,
    answer: '交差しないように、直前に引いた線と違う長さの線を引く',
    correctImage: Ans4,
    width: 4,
    height: 4,
    startPosition: new Point(0, 3),
    goalPosition: new Point(3, 3),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      if (ls.length === 0) {
        return [];
      }
      const paths: LineSegment[] = [];
      const pathMapping = [];
      let tempPath = [];
      let pathId = 0;
      pathMapping.push(0);
      tempPath.push(ls[0]);
      for (let i = 1; i < ls.length; ++i) {
        if (
          !tempPath[tempPath.length - 1].unitVector().eq(ls[i].unitVector())
        ) {
          paths.push(
            Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
          );
          ++pathId;
          tempPath = [];
        }
        pathMapping.push(pathId);
        tempPath.push(ls[i]);
      }
      paths.push(
        Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
      );

      const check = paths.map(
        (la, i) =>
          i === 0 ||
          (paths.every((lb, j) => i === j || !la.innerIntersect(lb)) &&
            i > 0 &&
            !la.size().eq(paths[i - 1].size()))
      );
      return pathMapping.map(e => check[e]);
    },
    hintRule: [
      {
        title: 'ルールのヒント１',
        text: '直前に引いた線との関係で、光るか光らないかが決まるようです。また交差すると光らないようです。',
        image: Hint4_1,
      },
      {
        title: 'ルールのヒント２',
        text: '直前に引いた線と異なる長さの線を引く必要があります。',
        image: Hint4_2,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント１',
        text: 'まずはスタートのすぐ右の点に、そのあとスタートのすぐ上にある点に繋ぎましょう。',
        image: Hint5_3,
      },
      {
        title: 'つなぎ方のヒント２',
        text: 'これを繰り返すと左半分が埋まるはずです。これが鏡映しになるように右半分も埋めましょう',
        image: Hint5_4,
      },
    ],
  },
  {
    stage: 6,
    answer: 'それぞれの線分が必ず２回交差する',
    correctImage: Ans6,
    width: 4,
    height: 4,
    startPosition: new Point(1, 3),
    goalPosition: new Point(2, 3),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      return ls.map(
        (la, i) =>
          ls.filter((lb, j) => i !== j && la.innerIntersect(lb)).length === 2
      );
    },
    hintRule: [
      {
        title: 'ルールのヒント１',
        text: '線の交差が関係しているようです。',
        image: Hint6_1,
      },
      {
        title: 'ルールのヒント２',
        text: '➁全ての線が必ず２回だけ交差するように線を引きましょう',
        image: Hint6_2,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント１',
        text: 'まずは時計まわりに一つ飛ばした先にある点（スタートの左の列の上側にある点）に線を引きましょう。',
        image: Hint6_3,
      },
      {
        title: 'つなぎ方のヒント２',
        text: '次の点、その次の点も、時計回りに一つ飛ばした先にある点に線を引いていきましょう。',
        image: Hint6_4,
      },
      {
        title: 'つなぎ方のヒント３',
        text: 'その次の点では左端の列の下側の点に線を引きます。あとは残りの3点の結び方を考えましょう。',
        image: Hint6_5,
      },
    ],
    points: [
      [false, true, true, false],
      [true, false, false, true],
      [true, false, false, true],
      [false, true, true, false],
    ],
  },
  {
    stage: 7,
    answer: 'どの線も必ず中心線をまたぐ',
    correctImage: Ans7,
    width: 4,
    height: 4,
    startPosition: new Point(0, 3),
    goalPosition: new Point(3, 3),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      if (ls.length === 0) {
        return [];
      }
      const paths: LineSegment[] = [];
      const pathMapping = [];
      let tempPath = [];
      let pathId = 0;
      pathMapping.push(0);
      tempPath.push(ls[0]);
      for (let i = 1; i < ls.length; ++i) {
        if (
          !tempPath[tempPath.length - 1].unitVector().eq(ls[i].unitVector())
        ) {
          paths.push(
            Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
          );
          ++pathId;
          tempPath = [];
        }
        pathMapping.push(pathId);
        tempPath.push(ls[i]);
      }
      paths.push(
        Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
      );

      const check = paths.map(
        la => (la.points[0].x * 2 - 3) * (la.points[1].x * 2 - 3) < 0
      );
      return pathMapping.map(e => check[e]);
    },
    hintRule: [
      {
        title: 'ルールのヒント１',
        text: '見えない線が鍵になっています。',
        image: Hint7_1,
      },
      {
        title: 'ルールのヒント２',
        text: '見えない線と交差する線が光っています。',
        image: Hint7_2,
      },
      {
        title: 'ルールのヒント３',
        text: '２列目と３列目のタテ列の間に見えない線が引かれています。これと交差する線が光るのです。',
        image: Hint7_3,
      },
    ],
    hintMethod: [],
  },
  {
    stage: 8,
    answer: '平行な線分が３本の状態でゴールする',
    correctImage: Ans8,
    width: 4,
    height: 4,
    startPosition: new Point(1, 3),
    goalPosition: new Point(2, 3),
    onCheck: (lines: Point[][]): boolean[] => {
      const ls = lines.map(line =>
        Seg(Pt(line[0].x, line[0].y), Pt(line[1].x, line[1].y))
      );
      if (ls.length === 0) {
        return [];
      }
      const paths: LineSegment[] = [];
      const pathMapping = [];
      let tempPath = [];
      let pathId = 0;
      pathMapping.push(0);
      tempPath.push(ls[0]);
      for (let i = 1; i < ls.length; ++i) {
        if (
          !tempPath[tempPath.length - 1].unitVector().eq(ls[i].unitVector())
        ) {
          paths.push(
            Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
          );
          ++pathId;
          tempPath = [];
        }
        pathMapping.push(pathId);
        tempPath.push(ls[i]);
      }
      paths.push(
        Seg(tempPath[0].points[0], tempPath[tempPath.length - 1].points[1])
      );

      const check = paths.map(
        (la, i) =>
          paths.filter((lb, j) => i !== j && la.similar(lb, true, false, false))
            .length === 2
      );
      return pathMapping.map(e => check[e]);
    },
    hintRule: [
      {
        title: 'ルールのヒント１',
        text: '他の線との関係が鍵になっています。',
        image: Hint8_1,
      },
      {
        title: 'ルールのヒント２',
        text: '平行な線がちょうど3本になるように線を結んでいきましょう',
        image: Hint8_2,
      },
    ],
    hintMethod: [
      {
        title: 'つなぎ方のヒント',
        text: '正解ルートの一つを途中まで結びました。ここから先は自分で考えてみましょう',
        image: Hint8_3,
      },
    ],
  },
];

import { CustomClaimContent } from '../../api/firestoreTypes';
import * as AuthActions from '../actions/authActions';
import 'firebase/auth';
import { User } from 'firebase/auth';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface AuthState {
  user: User | null;
  userInitialized: boolean;
  selectedOrgId: string | null;
  customClaim: CustomClaimContent | null;
}

export const initialAuthState: AuthState = {
  user: null,
  userInitialized: false,
  selectedOrgId: JSON.parse(
    window.localStorage.getItem('selectedOrgId') ?? 'null'
  ),
  customClaim: null,
};

const authReducer = reducerWithInitialState<AuthState>(initialAuthState)
  .case(AuthActions.setUser, (state, { user }): AuthState => {
    return {
      ...state,
      user,
      userInitialized: true,
    };
  })
  .case(AuthActions.setSelectedOrgId, (state, { orgId }): AuthState => {
    window.localStorage.setItem('selectedOrgId', JSON.stringify(orgId));
    return {
      ...state,
      selectedOrgId: orgId,
    };
  })
  .case(
    AuthActions.setUserCustomClaim,
    (state, { claimContent }): AuthState => {
      return {
        ...state,
        customClaim: claimContent,
      };
    }
  );

export default authReducer;

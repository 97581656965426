import { SingleHint, stages } from '../../../lib/Problems';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import Field, { FieldHandler } from '../../uiElements/Field';
import TimeGauge from '../../uiElements/TimeGauge';
import {
  faExclamationTriangle,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  Overlay,
  OverlayContent,
  SubButton,
  MainButton as _Button,
  TextWindow as _TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
// import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

interface GameProps {}

const duration = 8 * 60;
const unlockHintSec = 60;

export interface SingleResult {
  hintMethodOpenCount: number;
  hintRuleOpenCount: number;
  timeSec: number;
  success: boolean;
  rate?: number;
}

const Game: React.FC<GameProps> = () => {
  const { eventId } = useParams<'eventId'>();
  const uid = useCurrentUser()?.uid;
  const localRecordName = (name: string) => `${eventId}-${uid}-${name}`;
  const [currentSec, setCurrentSec] = useState<number>(
    localRecordName('currentSec'),
    duration
  );
  const [hint, setHint] = useState<SingleHint | null>(
    localRecordName('hint'),
    null
  );
  const [showHintOverlay, setShowHintOverlay] = useState<boolean>(
    localRecordName('showHintOverlay'),
    false
  );
  const [currentStage, setCurrentStage] = useState<number>(
    localRecordName('currentStage'),
    0
  );
  const [startSec, setStartSex] = useState<number>(
    localRecordName('startSec'),
    duration
  );
  const [correct, setCorrect] = useState<boolean>(
    localRecordName('correct'),
    false
  );
  const [showCorrectOverlay, setShowCorrectOverlay] = useState<boolean>(
    localRecordName('showCorrectOverlay'),
    false
  );
  const [error, setError] = useState<boolean>(localRecordName('error'), false);
  const [errorStr, setErrorStr] = useState<string>(
    localRecordName('errorStr'),
    ''
  );
  const [unlockHint, setUnlockHint] = useState<boolean>(
    localRecordName('unlockHint'),
    false
  );

  const [results, setResults] = useState<SingleResult[]>(
    localRecordName('results'),
    []
  );
  const [hintMethodOpenCount, setHintMethodOpenCount] = useState<number>(
    localRecordName('hintMethodOpenCount'),
    0
  );
  const [hintRuleOpenCount, setHintRuleOpenCount] = useState<number>(
    localRecordName('hintRuleOpenCount'),
    0
  );
  const fieldRef = React.useRef<FieldHandler>(null);

  const navigate = useNavigate();
  const goTo = (url: string, params: any) => {
    navigate(url, { state: params });
  };

  // タイムアップ
  const onFinish = () => {
    if (correct) {
      goTo(`/events/${eventId}/finish`, results);
    } else {
      const res = {
        hintMethodOpenCount,
        hintRuleOpenCount,
        timeSec: startSec - currentSec,
        success: false,
      };
      goTo(`/events/${eventId}/finish`, [...results, res]);
    }
  };

  const onNextStage = () => {
    if (currentStage == stages.length - 1) {
      // 全問クリア
      goTo(`/events/${eventId}/finish`, results);
    } else {
      setCurrentStage(prev => prev + 1);
      setCorrect(false);
      setShowCorrectOverlay(false);
      setTimeout(() => fieldRef.current?.onResetAll(), 0);
      setStartSex(currentSec);
      setUnlockHint(false);
      setHintMethodOpenCount(0);
      setHintRuleOpenCount(0);
    }
  };

  return (
    <CommonBG>
      <Overlay
        background='rgba(255,255,255,0.8)'
        isVisible={showCorrectOverlay}
      >
        <CorrectOverlayContent>
          <h2>正解</h2>
          <img src={stages[currentStage].correctImage} />
          <p>{stages[currentStage].answer}</p>

          <_Button color='positive' size='large' onClick={onNextStage}>
            確認
          </_Button>
        </CorrectOverlayContent>
      </Overlay>
      <Overlay
        isVisible={showHintOverlay}
        onClick={() => {
          setShowHintOverlay(false);
        }}
      >
        <OverlayContent>
          <h2>{hint?.title}</h2>
          {hint?.image != null && <img src={hint?.image} />}
          <p>{hint?.text}</p>
        </OverlayContent>
      </Overlay>
      <Wrapper>
        <TimeGauge
          duration={duration}
          timerKey={`ct2switch-${eventId}-${uid}`}
          onFinished={onFinish}
          onRefreshDuration={d => {
            setCurrentSec(d);
            if (unlockHint == false && unlockHintSec - (startSec - d) <= 0) {
              setUnlockHint(true);
            }
          }}
        />

        <TextWindow bracket>
          全ての点を通る一本の折線を引いてください。
        </TextWindow>

        <GameField>
          <Panel>
            <Field
              ref={fieldRef}
              question={currentStage + 1}
              widthCount={stages[currentStage].width}
              heightCount={stages[currentStage].height}
              startPosition={stages[currentStage].startPosition}
              goalPosition={stages[currentStage].goalPosition}
              points={stages[currentStage].points}
              finished={correct}
              onCheck={stages[currentStage].onCheck}
              onGoal={(passedPoints: number[], complete: boolean) => {
                let doublePassed = false;
                let zeroPassed = false;
                passedPoints.forEach(single => {
                  if (single == 0) zeroPassed = true;
                  if (single >= 2) doublePassed = true;
                });
                setError(zeroPassed || doublePassed || complete == false);
                if (zeroPassed) {
                  setErrorStr('全ての点を通っていません');
                } else if (doublePassed) {
                  setErrorStr('同じ点を複数回通っています');
                } else if (complete == false) {
                  setErrorStr('光っていない線があります');
                } else {
                  const res = {
                    hintMethodOpenCount,
                    hintRuleOpenCount,
                    timeSec: startSec - currentSec,
                    success: true,
                  };
                  setResults([...results, res]);

                  setCorrect(true);
                  setTimeout(() => {
                    setShowCorrectOverlay(true);
                  }, 1000);
                }
              }}
            />
            <ErrorWindow show={error}>
              <Error bracket>
                <div>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  {errorStr}
                </div>
                <SubButton
                  color='negative'
                  size='medium'
                  onClick={() => {
                    setError(false);
                  }}
                >
                  OK
                </SubButton>
              </Error>
            </ErrorWindow>
            <Side>
              <Hint>
                <h2>
                  ヒントを見た回数 {hintMethodOpenCount + hintRuleOpenCount}/
                  {stages[currentStage].hintRule.length +
                    stages[currentStage].hintMethod.length}
                </h2>
                {stages[currentStage].hintMethod.map((single, i) => {
                  return (
                    <HintButton
                      locked={!unlockHint}
                      key={`method-hint-${i}`}
                      disabled={hintMethodOpenCount >= i ? false : true}
                      opened={hintMethodOpenCount - 1 >= i}
                      onClick={() => {
                        setShowHintOverlay(true);
                        setHint(single);
                        if (hintMethodOpenCount <= i)
                          setHintMethodOpenCount(hintMethodOpenCount + 1);
                      }}
                    >
                      {single.title}を見る
                    </HintButton>
                  );
                })}
                {stages[currentStage].hintRule.map((single, i) => {
                  return (
                    <HintButton
                      locked={!unlockHint}
                      key={`rule-hint-${i}`}
                      disabled={hintRuleOpenCount >= i ? false : true}
                      opened={hintRuleOpenCount - 1 >= i}
                      onClick={() => {
                        setShowHintOverlay(true);
                        setHint(single);
                        if (hintRuleOpenCount <= i)
                          setHintRuleOpenCount(hintRuleOpenCount + 1);
                      }}
                    >
                      {single.title}を見る
                    </HintButton>
                  );
                })}
                {!unlockHint && (
                  <HintOpen>
                    あと{unlockHintSec - (startSec - currentSec)}
                    秒で解放されます
                  </HintOpen>
                )}
              </Hint>
              <Buttons>
                <SubButton
                  color='negative'
                  size='medium'
                  onClick={() => {
                    fieldRef.current?.onUndo();
                    setError(false);
                  }}
                >
                  <FontAwesomeIcon icon={faUndo} />
                  一手戻す
                </SubButton>
                <SubButton
                  color='negative'
                  size='medium'
                  onClick={() => {
                    fieldRef.current?.onReset();
                    setError(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  リセット
                </SubButton>
              </Buttons>
            </Side>
          </Panel>
        </GameField>
      </Wrapper>
    </CommonBG>
  );
};

interface errWindowProps {
  show: boolean;
}

const CorrectOverlayContent = styled.div`
  width: 600px;
  padding: 1rem 1rem;
  padding-bottom: 3rem;
  border: 3px solid #05aa70;

  background: rgba(81, 207, 102, 0.25);
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 4rem;
  }

  img {
    width: 400px;
    height: 100%;
    margin: 1rem auto;
    display: block;
  }

  p {
    background: #f3f4f6;
    border: 1px solid #ced4da;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1.2rem 0;
    margin: 1rem auto;
    width: 500px;
  }

  button {
    display: block;
    margin: 1rem auto;
  }
`;

const ErrorWindow = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 600px;
  height: 600px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p: errWindowProps) => (p.show ? `` : `display: none;`)}
`;
const Error = styled(_TextWindow)`
  color: #ea0e49 !important;
  min-width: initial !important;
  min-height: initial !important;

  svg {
    margin-right: 1rem;
  }

  &:before,
  &:after {
    border-color: #ea0e49 !important;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
`;

const TextWindow = styled(_TextWindow)`
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  max-width: 800px;
`;

const Panel = styled.div`
  display: flex;
  position: relative;
`;

const HintOpen = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #868e96;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 50%;
  white-space: nowrap;
`;

const Hint = styled.div`
  background: #f3f4f6;
  border: 1px solid #ced4da;
  position: relative;

  h2 {
    font-size: 1.8rem;
    text-align: center;
    padding: 4px 0;
    padding-bottom: 6px;
    color: white;
    background: #343a40;
  }
`;
interface HintButtonProps {
  locked?: boolean;
  disabled?: boolean;
  opened: boolean;
}

const HintButton = styled.div`
  background: #868e96;
  border-radius: 5px;
  border-style: none;
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 10px;
  padding: 20px 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;

  ${(p: HintButtonProps) =>
    p.locked
      ? `
        cursor: default;
        opacity: 0.1;
        pointer-events: none;
      `
      : p.disabled
      ? `
        cursor: default;
        opacity: 0.4;
        pointer-events: none;
      `
      : `
      &:hover {
        background: #EA0E49;
      }
      `}

  ${(p: HintButtonProps) =>
    p.opened
      ? `
        box-shadow: 0 0 0 3px #EA1949 inset, 0 0 0 6px #FFF inset;
      `
      : `
      `}
`;

const Side = styled.div`
  margin-left: 2rem;
  width: 290px;
  position: relative;
`;

const GameField = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const Buttons = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  width: 290px;

  button {
    flex: 1;

    svg {
      margin-right: 8px;
    }
  }
  button:first-child {
    margin-right: 10px;
  }
`;

export default Game;
